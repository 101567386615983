import { Injectable } from '@angular/core';
import moment from 'moment';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import {
  FlightSearchResult,
  InfoIndicators,
} from '../models/flight-search-result';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { Observable } from 'rxjs';
import { getUserState } from 'src/app/shared/store/reducers';
import { User } from 'src/app/models/user';
import { map } from 'rxjs/operators';
interface FlightSearchPayload {
  airlineCode: string;
  businessUnit: string;
  employeeLogin: number;
  appSessionId: string;
  siteMinderEmployeeId: number;
  flightInfoRequestKeys: {
    airlineCode: string;
    flightNumber: number | null;
    flightOriginationDate: string;
    departureStation: string | null;
    arrivalStation: string | null;
    dupDepartureCode: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FlightSearchService {
  baseUrl = `${environment.apiBaseURL}`;
  userObs: Observable<UserState> = this.store.select(getUserState);
  user: User = new User();

  constructor(
    private httpService: HttpService,
    private store: Store<{ user: UserState }>
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  getFlights(
    flightOrigDate: string,
    flightNum: string | null,
    depStation: string | null,
    arrStation: string | null,
    airlineCode: string | null = null
  ): Observable<FlightSearchResult[]> {
    const url = `${this.baseUrl}/v1/searchFlightDetails`;

    const payload: FlightSearchPayload = {
      airlineCode: this.user.airlineCode ?? 'AA',
      businessUnit: this.user.businessUnit ?? '',
      employeeLogin: this.user.employeeNumber ?? 0,
      appSessionId: 'browser',
      siteMinderEmployeeId: this.user.employeeNumber ?? 0,
      flightInfoRequestKeys: {
        airlineCode:
          airlineCode !== null ? airlineCode : this.user.airlineCode ?? 'AA',
        flightNumber: flightNum === null ? null : Number(flightNum),
        flightOriginationDate: flightOrigDate,
        arrivalStation: arrStation?.toUpperCase() ?? null,
        departureStation: depStation?.toUpperCase() ?? null,
        dupDepartureCode: 0,
      },
    };

    const response = this.httpService
      .postWithResponseService(url, payload)
      .pipe(
        map((data) => {
          const flightList: FlightSearchResult[] = [];

          for (const flight of data.body.flightInfoResponse || []) {
            const depTimes = flight.leg?.times.LTD ?? flight.leg?.times.STD;
            const arrTimes = flight.leg?.times.LTA ?? flight.leg?.times.STA;
            const depDateTime = moment(depTimes);
            const arrDateTime = moment(arrTimes);
            const timeDiff = moment.duration(
              flight.leg?.times.timeZoneDifferenceHHMM
            );
            const duration = moment
              .duration(arrDateTime.diff(depDateTime))
              .add(timeDiff);
            const flightNum: string = flight.key.fltNum;
            const depStation: string = flight.key.depSta;
            const depDate: string = depDateTime.format('MMM DD').toUpperCase();
            const depTime: string = depDateTime.format('HH:mm');
            const arrDate: string = arrDateTime.format('MMM DD').toUpperCase();
            const arrTime: string = arrDateTime.format('HH:mm');
            const arrStation: string = flight.leg?.stations.arr;
            const enRoute = `${duration.hours()}h ${duration.minutes()}m`;
            const airlineCode = flight.key.airlineCode.IATA;
            const noseNum = flight.leg?.equipment
              ? flight.leg?.equipment.assignedTail
              : null;
            const kickOffFlt = flight.leg?.type
              ? flight.leg?.type.kickOffFlt
              : 'no';
            const postFlightInspectionRequired = flight.infoIndicators
              ? flight.infoIndicators.postFlightInspectionRequired
              : false;
            const fltKeysOwns = flight.infoIndicators
              ? flight.infoIndicators.fltKeysOwns
              : false;
            const infoIndicators = new InfoIndicators(
              kickOffFlt,
              postFlightInspectionRequired,
              fltKeysOwns
            );
            const departureGate = flight.leg?.departureArrival?.depGate;
            const arrivalGate = flight.leg?.departureArrival?.arrGate;

            flightList.push(
              new FlightSearchResult(
                flightNum,
                depDate,
                depStation,
                depTime,
                enRoute,
                arrDate,
                arrStation,
                arrTime,
                airlineCode,
                noseNum,
                depDateTime.format('YYYY-MM-DD'),
                infoIndicators,
                null,
                departureGate,
                arrivalGate
              )
            );
          }
          return flightList;
        })
      );

    return response;
  }
}
