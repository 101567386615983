import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'HoursConverter',
  standalone: false,
})
export class HoursConverterPipe implements PipeTransform {
  transform(value: number): string {
    const totalCredits = value;
    const hours = totalCredits / 60;
    const creditHours = Math.floor(hours);
    const minutes = (hours - creditHours) * 60;
    const creditMinutes = Math.round(minutes);
    return creditHours + 'h ' + creditMinutes + 'm';
  }
}
