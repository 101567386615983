import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from 'src/app/routes/admin/services/user.service';
import { UserListElement } from 'src/app/routes/admin/models/user';
import { UserModalComponent } from '../user-modal/user-modal.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDeleteDialogComponent } from '../user-delete-dialog/user-delete-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  standalone: false,
})
export class UserListComponent implements OnInit {
  displayedColumns: string[] = [
    'employeeId',
    'firstName',
    'lastName',
    'businessUnit',
    'active',
    'updatedBy',
    'lastUpdatedDateTime',
    'actions',
  ];

  userData = new MatTableDataSource<UserListElement>([]);

  isLoaderActive = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.reloadData();
  }

  onCreateUserClick() {
    const dialogRef = this.dialog.open(UserModalComponent);
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'success') {
        this.reloadData();
        this._snackBar.open('user created', undefined, { duration: 3000 });
      }
    });
  }

  onEditUserClick(element: UserListElement) {
    const dialogRef = this.dialog.open(UserModalComponent, {
      data: {
        firstName: element.firstName,
        lastName: element.lastName,
        employeeId: element.employeeId,
        businessUnit: element.businessUnit,
        adminId: element.adminId,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'success') {
        this.reloadData();
        this._snackBar.open(
          `${element.employeeId} updated successfully`,
          undefined,
          { duration: 3000 }
        );
      }
    });
  }

  onDeleteClick(element: UserListElement) {
    const dialogRef = this.dialog.open(UserDeleteDialogComponent, {
      data: {
        firstName: element.firstName,
        lastName: element.lastName,
        employeeId: element.employeeId,
        businessUnit: element.businessUnit,
        adminId: element.adminId,
      },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data === 'success') {
        this.reloadData();
        this._snackBar.open(`${element.employeeId} deleted`, undefined, {
          duration: 3000,
        });
      }
    });
  }

  reloadData() {
    this.userService.getUserData().subscribe(
      (data: any) => {
        this.isLoaderActive = false;
        if (data.userDetails && data.userDetails.length > 0) {
          this.userData = new MatTableDataSource(data.userDetails);
          this.userData.paginator = this.paginator;
          this.userData.sort = this.sort;
        }
      },
      () => {
        this.isLoaderActive = false;
      }
    );
  }
}
