import { Component, OnInit } from '@angular/core';
import { FlightSearchService } from '../../services/flight-search.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FlightSearchResult } from '../../models/flight-search-result';
import { IndicatorConstants } from '../../crew-constants';
@Component({
  selector: 'app-flight-details-page',
  templateUrl: './flight-details-page.component.html',
  styleUrls: ['./flight-details-page.component.scss'],
  standalone: false,
})
export class FlightDetailsPageComponent implements OnInit {
  flight: FlightSearchResult;
  indicatorVisible: boolean | null;
  indicatorText: string;
  flightSearchService: FlightSearchService;
  flightNum: string;
  airlineCode: string;
  depStation: string;
  arrStation: string;
  flightOrigDate: string;

  constructor(
    flightSearchService: FlightSearchService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.flightSearchService = flightSearchService;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.flightNum = params['flightNum'];
      this.airlineCode = params['airlineCode'];
      this.depStation = params['depStation'];
      this.arrStation = params['arrStation'];
      this.flightOrigDate = params['flightStartTime'];

      this.flightSearchService
        .getFlights(
          this.flightOrigDate,
          this.flightNum,
          this.depStation,
          this.arrStation,
          this.airlineCode
        )
        .subscribe((x) => {
          this.flight = x[0];
          if (this.flight && this.flight.infoIndicators) {
            this.indicatorVisible =
              this.flight.infoIndicators.postFlightInspectionIndicator ||
              this.flight.infoIndicators.rightStartIndicator;
            if (this.flight.infoIndicators.postFlightInspectionIndicator) {
              this.indicatorText = IndicatorConstants.postFlightInspectionText;
            } else if (this.flight.infoIndicators.rightStartIndicator) {
              this.indicatorText = IndicatorConstants.rightStartText;
            }
          }
        });
    });
  }

  onBackClick(): void {
    this.location.back();
  }
}
