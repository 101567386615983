import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SequenceOverviewDetails } from '../../../../services/sequence-details.service';

@Component({
  selector: 'app-sequence-card',
  templateUrl: './sequence-card.component.html',
  styleUrls: ['./sequence-card.component.scss'],
  standalone: false,
})
export class SequenceCardComponent {
  @Input() flightResult: SequenceOverviewDetails;

  constructor(private router: Router) {}

  backToLanding() {
    this.router.navigate(['landing']);
  }
}
