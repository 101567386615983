import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PingAuthenticationModule,
  PingAuthenticationConfig,
} from '@techops-ui/ping-authentication';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
import { UserSessionEffects } from './shared/store/effects/user-session.effects';
import { PreflightDocumentDataEffects } from './shared/store/effects/pre-flight-documents.effects';
import { reducers, metaReducers } from './shared/store/reducers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CrewModule } from './routes/crew/crew.module';
import { AdminModule } from './routes/admin/admin.module';
import { AdminAuthGuard } from 'src/app/routes/admin/admin-auth.guards';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { RouterModule } from '@angular/router';

export const PING_SERVER_CONFIG: PingAuthenticationConfig = {
  authority: 'https://idptest.aa.com',
  client_id: 'crewcheckinccilite-test',
  use_html5_routing: true,
  scope: 'openid profile',
  idle_threshold: 1800,
  timeout_duration: 1800,
  post_logout_redirect_uri: 'https://ccilite-dev.aa.com/index.html',
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    MaterialModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PingAuthenticationModule.forRoot(PING_SERVER_CONFIG),
    CrewModule,
    AdminModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([UserSessionEffects, PreflightDocumentDataEffects]),
    AppRoutingModule,
    NgxExtendedPdfViewerModule,
    RouterModule,
  ],
  providers: [AdminAuthGuard, provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
