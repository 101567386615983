import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User, UserInfo } from 'src/app/models/user';
import moment from 'moment';
import { jsonClone } from 'src/app/shared/util/util';
import {
  tripSignInConstants,
  fitForDutyConstants,
  dialogText,
} from 'src/app/routes/crew/crew-constants';
import { NextActivity } from 'src/app/routes/crew/models/sequence-list-results';
import { SharedDialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { configureSharedDialog } from 'src/app/shared/components/dialog/dialog.component';
import { SequenceDetailsService } from 'src/app/routes/crew/services/sequence-details.service';
import { FfdFlightPlanService } from 'src/app/routes/crew/services/ffd-flight-plan.service';
import * as UserSessionActions from 'src/app/shared/store/actions/user-session.actions';
import { TripSignInService } from '../../../../shared/services/trip-sign-in.service';

@Component({
  selector: 'app-sequence',
  templateUrl: './sequence.component.html',
  styleUrls: ['./sequence.component.scss'],
  standalone: false,
})
export class SequenceComponent implements OnInit {
  sequenceEndDate = '';

  flightDutyPeriods: any = [];

  nextActivity: NextActivity | any;

  signInText: string;

  readonly tripSignInConstants = tripSignInConstants;

  userInfo: User;

  constructor(
    private store: Store<{ user: UserState }>,
    private router: Router,
    private sequenceDetailsService: SequenceDetailsService,
    private sharedDialog: MatDialog,
    private ffdFlightPlanService: FfdFlightPlanService,
    private tripSignInService: TripSignInService
  ) {}

  ngOnInit() {
    this.store.select('user').subscribe((result) => {
      this.userInfo = result.user;
      this.flightDutyPeriods = jsonClone(result.user.flightDutyPeriods);
      this.nextActivity = jsonClone(result.user.nextActivity);
      this.sequenceEndDate = this.showSequenceEndDate(this.flightDutyPeriods);
    });

    this.tripSignInService.tripSignIn.subscribe((data) => {
      if (data === true) {
        this.nextActivity.isSignedIn = true;
        if (
          this.flightDutyPeriods &&
          this.flightDutyPeriods.length > 0 &&
          this.flightDutyPeriods[0].flightLegs.length > 0 &&
          this.flightDutyPeriods[0].flightLegs[0].fltPlanSignature
        ) {
          this.flightDutyPeriods[0].flightLegs[0].fltPlanSignature.confirmedFFD =
            true;
        }
      } else {
        return;
      }
    });
  }

  navigateToSequenceOverview() {
    this.sequenceDetailsService.getFlights().subscribe((result) => {
      if (result) {
        this.router.navigate(['/sequence-overview']);
      } else {
        alert('Something Went Wrong');
      }
    });
  }

  /**
   * Show sequence end date
   * @param flightDutyPeriods
   */
  showSequenceEndDate(flightDutyPeriods: any) {
    if (flightDutyPeriods && flightDutyPeriods.length > 0) {
      const lastFlightDutyPeriod =
        flightDutyPeriods[flightDutyPeriods.length - 1];
      if (lastFlightDutyPeriod.flightLegs.length > 0) {
        const lastFlightLeg =
          lastFlightDutyPeriod.flightLegs[
            lastFlightDutyPeriod.flightLegs.length - 1
          ];
        return lastFlightLeg.scheduled.arrivalDateTime.localTime;
      }
    }
    return null;
  }

  /**
   * Redirect to flight details page
   * @param $event
   */
  public goToFlightDetailsPage($event: any) {
    const {
      flightNumber,
      airlineCode,
      departureStation,
      arrivalStation,
      flightOriginationDate,
    } = $event;
    this.router.navigate([
      `flightDetails/${flightNumber}/${airlineCode}/${departureStation}/${arrivalStation}/${flightOriginationDate}`,
    ]);
  }

  /**
   * On click toggle flight duty dropdown
   * @param value
   * @param flightDuty
   */
  public toggleFlightDutyDropdown(value: any, flightDuty: any) {
    flightDuty.isFlightDutyOpen = value;
  }

  /**
   * On successful update user store
   */
  updateUserStoreData() {
    const userInfo: UserInfo = new UserInfo(this.userInfo);
    this.store.dispatch(UserSessionActions.loginAction({ userInfo }));
  }

  /**()
   * on click execute ffd command [confirmedFFD, isFFDEligible, signedFlightPlan]
   * @param $event
   * confirmFFD - captain, FO
   */
  executeFFDcommand($event: any) {
    const {
      flightNumber,
      flightOriginationDate,
      departureStation,
      arrivalStation,
      type,
      departureDuplicateCode,
    } = $event;
    const departureDateTime =
      $event.actual?.departureDateTime?.localTime ||
      $event.scheduled?.departureDateTime?.localTime ||
      '';
    const depTime = moment(departureDateTime).format('HHmm');
    let dialogConfig = new MatDialogConfig<any>();
    this.ffdFlightPlanService
      .confirmFfd(
        flightNumber,
        flightOriginationDate,
        departureStation,
        arrivalStation,
        depTime,
        departureDuplicateCode
      )
      .subscribe((data: any) => {
        if (data && (data.flightPlanSigned || data.fitForDuty)) {
          this.updateUserStoreData();
          let messageText = dialogText.successText;
          if (data.fitForDuty) {
            messageText = fitForDutyConstants.successText;
          }
          dialogConfig = configureSharedDialog(messageText, data.returnData);
        } else {
          dialogConfig = configureSharedDialog(
            dialogText.failureText,
            data.returnData,
            type === 'confirmFFD'
          );
        }
        const activeDialog = this.sharedDialog.open(
          SharedDialogComponent,
          dialogConfig
        );
        activeDialog.afterClosed().subscribe((data) => {
          if (data.reason === 'try again') {
            this.executeFFDcommand($event);
          }
        });
      });
  }
}
