import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  fitForDutyConstants,
  flightPlanConstants,
} from 'src/app/routes/crew/crew-constants';

@Component({
  selector: 'app-flight-info-overview',
  templateUrl: './flight-info-overview.component.html',
  styleUrls: ['./flight-info-overview.component.scss'],
  standalone: false,
})
export class FlightInfoOverviewComponent {
  @Input() flightDuty: any = {};

  @Input() nextActivity: any = {};

  @Output() onClickFlightDetails = new EventEmitter<object>();

  @Output() onExecuteFFDcommand = new EventEmitter<object>();

  readonly fitForDutyConstants = fitForDutyConstants;

  flightPlantext: string;

  public onClickFlightLeg(leg: any) {
    this.onClickFlightDetails.emit({
      flightNumber: leg.flightNumber,
      airlineCode: leg.airlineCode,
      departureStation: leg.departureStation,
      arrivalStation: leg.arrivalStation,
      flightOriginationDate: leg.flightOriginationDate,
    });
  }

  public toggleLayover(value: any, leg: any) {
    leg.isLayoverOpen = value;
  }

  /**
   * on click execute FFD command
   * @param leg
   * @param i
   */
  public executeFfdCommand(leg: any, type: string) {
    leg.type = type;
    this.onExecuteFFDcommand.emit(leg);
  }

  isFFDEligible(leg: any) {
    if (
      leg?.fltPlanSignature &&
      !leg?.fltPlanSignature?.signedFlightPlan &&
      leg?.fltPlanSignature?.signedFlightPlan !== null &&
      !leg?.fltPlanSignature?.confirmedFFD
    ) {
      return leg?.flightSignature?.signedFlightPlan;
    }
    return leg?.fltPlanSignature && leg?.fltPlanSignature?.isFFDEligible;
  }

  isSignFlightPlanEligible(leg: any) {
    if (leg?.fltPlanSignature) {
      if (leg?.fltPlanSignature?.signedFlightPlan === null) {
        return false;
      }
    }
    return leg?.fltPlanSignature && leg?.fltPlanSignature?.isFFDEligible;
  }

  confirmFFD(leg: any) {
    return leg.fltPlanSignature.confirmedFFD;
  }

  confirmFlightPlanSign(leg: any) {
    if (
      leg?.legIndex === 1 ||
      (leg?.fltPlanSignature && leg?.fltPlanSignature?.confirmedFFD)
    ) {
      this.flightPlantext = flightPlanConstants.legOneFlightPlanNotSigned;
    } else {
      this.flightPlantext = flightPlanConstants.legTwoFlightPlanNotSigned;
    }
    if (leg?.fltPlanSignature?.signedFlightPlan) {
      this.flightPlantext = flightPlanConstants.flightPlanSigned;
    }
    return leg?.fltPlanSignature?.signedFlightPlan;
  }
}
