import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()
export class AdminAuthGuard {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(): boolean {
    const isAuthenticated = this.authService.isAdminUser();
    if (!isAuthenticated) {
      this.router.navigate(['/']);
    }
    return isAuthenticated;
  }
}
