import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateUserModel } from '../../models/user';
import { UserService } from '../../services/user.service';
import { businessUnits } from 'src/app/shared/constants';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
  standalone: false,
})
export class UserModalComponent implements OnInit {
  @ViewChild('userForm') userForm: UntypedFormGroup;
  createUserModel = new CreateUserModel();
  initialCreateUserModel = new CreateUserModel();
  crudType = 'CREATE';
  isUpdate = false;
  disableSaveButton = true;
  businessUnits = businessUnits;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserModalComponent>,
    private userService: UserService,
    private _snackBar: MatSnackBar
  ) {}
  ngOnInit(): void {
    if (this.data) {
      this.crudType = 'UPDATE';
      this.isUpdate = true;
      this.createUserModel.employeeFirstName = this.data.firstName;
      this.createUserModel.employeeLastName = this.data.lastName;
      this.createUserModel.employeeLogin = this.data.employeeId;
      this.createUserModel.businessUnit = this.data.businessUnit;
      this.createUserModel.adminId = this.data.adminId;

      this.initialCreateUserModel = _.cloneDeep(this.createUserModel);
    }
  }

  onSubmitForm() {
    this.userService
      .crudUser(this.createUserModel, this.crudType)
      .subscribe((response) => {
        if (response) {
          this.dialogRef.close('success');
        } else {
          this._snackBar.open('something went wrong', undefined, {
            duration: 3000,
          });
        }
      });
  }

  onInputChange() {
    this.disableSaveButton =
      this.userForm.invalid ||
      (this.isUpdate &&
        this.createUserModel.employeeFirstName ===
          this.initialCreateUserModel.employeeFirstName &&
        this.createUserModel.employeeLastName ===
          this.initialCreateUserModel.employeeLastName &&
        this.createUserModel.businessUnit ===
          this.initialCreateUserModel.businessUnit);
  }
}
