import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserState } from 'src/app/shared/store/reducers';
import { UserState } from 'src/app/shared/store/reducers/user-session.reducer';
import { User } from 'src/app/models/user';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.services';
import { environment } from 'src/environments/environment';
import { FosCheatCommand } from '../../../../models/FosCheatCommand';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-fos-command-list',
  templateUrl: './fos-command-list.component.html',
  styleUrls: ['./fos-command-list.component.scss'],
  standalone: false,
})
export class FosCommandListComponent implements OnInit {
  userObs: Observable<UserState> = this.store.select(getUserState);

  user: User = new User();

  public fosCommands: FosCheatCommand[] = [];

  constructor(
    private store: Store<{ user: UserState }>,
    private router: Router,
    private httpService: HttpService
  ) {
    this.userObs.subscribe({
      next: (userObs) => {
        this.user = userObs.user;
      },
    });
  }

  ngOnInit(): void {
    const bu = this.user.businessUnit;
    if (bu) {
      const url = `${environment.apiBaseURL}/v1/getFosCheatCommands`;
      this.httpService
        .get<FosCheatCommand[]>(url)
        .pipe(first())
        .subscribe((cheats) => {
          this.fosCommands = cheats.filter((c) => c.businessUnits.includes(bu));
        });
    }
  }

  /**
   * Redirect to another path
   * @param e - event
   */
  public redirectToLandingPage() {
    this.router.navigateByUrl('/landing');
  }

  public toggleDropdown(command: FosCheatCommand) {
    command.isOpen = !command.isOpen;
  }
}
