<section *ngIf="!isPdf" class="fos-response-dialog">
  <div class="dialog-header">
    <mat-grid-list cols="3" rowHeight="50px">
      <mat-grid-tile [colspan]="2" [rowspan]="1" class="grid-title-start">
        <div [mat-dialog-close]="true" mat-card-avatar>
          <mat-icon class="material-icons-outlined cursor-pointer"
            >highlight_off</mat-icon
          >
        </div>
      </mat-grid-tile>
      <mat-grid-tile
        [colspan]="1"
        [rowspan]="1"
        class="grid-title-end cursor-pointer"
        *ngIf="!isPdf"
      >
        <div mat-card-avatar>
          <mat-icon class="material-icons-outlined" (click)="downloadPDF()">
            cloud_download
          </mat-icon>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="dialog-content">
    <mat-dialog-content>
      <h1 mat-dialog-title>{{ dialogData.dialogTitle | uppercase }}</h1>
      <mat-divider></mat-divider>
      <p class="fos-dialogBody">{{ dialogData.dialogBody }}</p>
    </mat-dialog-content>
  </div>
</section>

<section class="pdf-viewer" *ngIf="isPdf">
  <ngx-extended-pdf-viewer
    [src]="pdfData"
    [contextMenuAllowed]="false"
    [showToolbar]="true"
    [showSidebarButton]="false"
    [showFindButton]="true"
    [showPagingButtons]="false"
    [showDrawEditor]="false"
    [showStampEditor]="false"
    [showTextEditor]="false"
    [showZoomButtons]="false"
    [showPresentationModeButton]="false"
    [showOpenFileButton]="false"
    [showPrintButton]="false"
    [showDownloadButton]="true"
    [showSecondaryToolbarButton]="true"
    [showRotateButton]="false"
    [showHandToolButton]="false"
    [showScrollingButton]="false"
    [showSpreadButton]="false"
    [showPropertiesButton]="false"
  ></ngx-extended-pdf-viewer>
</section>
