import moment from 'moment';

export class Util {
  flightStationValidation = '^[a-zA-Z]{3}$';
  flightNumValidation = /^\d{1,4}$/;
}
export function dateToStringYYYYMMDD(date: Date): string {
  return moment(date).format('YYYY-MM-DD');
}

/**
 *
 * @param inputValue - Eg: MAR 31 => 31MAR
 * @returns
 */
export function getDDMMM(inputValue: string): string {
  return moment(inputValue).format('DDMMM').toUpperCase();
}
/**
 *
 * @param inputValue - Eg: 15:20 => 1520
 * @returns
 */
export function getHHmm(inputValue: string, format = 'HH:mm'): string {
  return moment(`${inputValue}`, format).format('HHmm');
}

export function getDay(inputValue: string): string {
  return moment(inputValue).format('DD');
}

export function getTimeDiffInMin(inputTimeOne: any, inputTimeTwo: any): number {
  return moment(inputTimeOne).diff(moment(inputTimeTwo), 'minutes');
}

export function jsonClone(value: any) {
  return JSON.parse(JSON.stringify(value));
}
