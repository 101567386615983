import * as i0 from '@angular/core';
import { InjectionToken, inject, InjectFlags, Injectable, EventEmitter, Inject, Component, NgModule, ApplicationRef, Directive, Input, ɵstringify, APP_INITIALIZER } from '@angular/core';
import * as Oidc from 'oidc-client-ts';
import { WebStorageStateStore, UserManager } from 'oidc-client-ts';
function SessionTimeoutWarningDialogComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2)(3, "div", 3)(4, "div", 4)(5, "div", 5)(6, "h4", 6)(7, "i", 7);
    i0.ɵɵtext(8, "\u26A0");
    i0.ɵɵelementEnd();
    i0.ɵɵtext(9, " Warning - Session Timeout ");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(10, "div", 8);
    i0.ɵɵtext(11);
    i0.ɵɵpipe(12, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(13, "div", 9)(14, "button", 10);
    i0.ɵɵlistener("click", function SessionTimeoutWarningDialogComponent_ng_container_0_Template_button_click_14_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.endSession());
    });
    i0.ɵɵtext(15, "End Session Now");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(16, "button", 11);
    i0.ɵɵlistener("click", function SessionTimeoutWarningDialogComponent_ng_container_0_Template_button_click_16_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.extendSession());
    });
    i0.ɵɵtext(17, "Renew Session");
    i0.ɵɵelementEnd()()()()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(11);
    i0.ɵɵtextInterpolate1(" Your session is about to expire in ", i0.ɵɵpipeBind1(12, 1, ctx_r1.secondsLeft$), " seconds. ");
  }
}
function PingOverlayComponent_div_0_h2_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2", 4);
    i0.ɵɵtext(1, "Loading ...");
    i0.ɵɵelementEnd();
  }
}
function PingOverlayComponent_div_0_h2_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "h2", 4);
    i0.ɵɵtext(1, "Login Failed.");
    i0.ɵɵelementStart(2, "a", 5);
    i0.ɵɵtext(3, "Try Again...");
    i0.ɵɵelementEnd()();
  }
}
function PingOverlayComponent_div_0_pre_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "pre");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1("    ", i0.ɵɵpipeBind1(2, 1, ctx_r0.errorMessage$), "\n  ");
  }
}
function PingOverlayComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵtemplate(1, PingOverlayComponent_div_0_h2_1_Template, 2, 0, "h2", 2);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵtemplate(3, PingOverlayComponent_div_0_h2_3_Template, 4, 0, "h2", 2);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵtemplate(5, PingOverlayComponent_div_0_pre_5_Template, 3, 3, "pre", 3);
    i0.ɵɵpipe(6, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx_r0.loading$));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(4, 5, ctx_r0.error$));
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(6, 7, ctx_r0.error$));
  }
}
export { User } from 'oidc-client-ts';
import { bufferTime, filter, tap, switchMap, takeUntil, finalize, distinctUntilChanged, map, take, scan, first, catchError, delay } from 'rxjs/operators';
import { Subject, Subscription, merge, fromEvent, from, interval, of, BehaviorSubject, combineLatest, forkJoin, throwError, firstValueFrom } from 'rxjs';
import * as i2 from '@angular/router';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { Buffer } from 'buffer';
import * as i1 from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import * as i2$1 from '@angular/common';
import { CommonModule } from '@angular/common';
var Log;
(function (Log) {
  Log[Log["NONE"] = 0] = "NONE";
  Log[Log["ERROR"] = 1] = "ERROR";
  Log[Log["WARN"] = 2] = "WARN";
  Log[Log["INFO"] = 3] = "INFO";
  Log[Log["DEBUG"] = 4] = "DEBUG";
})(Log || (Log = {}));
const _PING_CONFIG = new InjectionToken('PingConfig Internal');
const PING_CONFIG = new InjectionToken('PingConfig');
const _RUNTIME_PING_CONFIG = new InjectionToken('RuntimePingConfig');
const _PING_CONFIG_FALLBACK = new InjectionToken('PingConfigFallBack', {
  factory: () => {
    const config = inject(PING_CONFIG, InjectFlags.Optional);
    return config || {};
  }
});
const _PRELOADED_PING_CONFIG = new InjectionToken('PreloadedPingConfig', {
  factory: () => {
    const config = inject(PING_CONFIG, InjectFlags.Optional);
    return config ? true : false;
  }
});
const PING_APP_ENVIRONMENT = new InjectionToken('PingAppEnvironment', {
  factory: () => {
    const config = inject(_RUNTIME_PING_CONFIG, InjectFlags.Optional);
    return config && config.environment ? config.environment : {};
  }
});
const AZURE_AD_PROVIDER = 'https://login.microsoftonline.com';
const AUTHORIZATION = 'Authorization';
function getStorage(useSessionStorage) {
  return new WebStorageStateStore({
    store: useSessionStorage ? window.sessionStorage : window.localStorage
  });
}

/**
 * User's idle service.
 */
class UserIdleService {
  constructor(_ngZone) {
    this._ngZone = _ngZone;
    this.timerStart$ = new Subject();
    this.idleDetected$ = new Subject();
    this.timeout$ = new Subject();
    /**
     * Idle value in milliseconds.
     * Default equals to 14 minutes.
     */
    this.idleTime = 14 * 60;
    /**
     * Idle buffer wait time milliseconds to collect user action
     * Default equals to 1 Sec.
     */
    this.idleSensitivityMillisec = 1000;
    /**
     * Timeout value in seconds.
     * Default equals to 1 minute.
     */
    this.timeout = 60;
    /**
     * Timeout status.
     */
    this.isTimeout = false;
    /**
     * Timer of user's inactivity is in progress.
     */
    this.isInactivityTimer = false;
    this.isIdleDetected = false;
    this.idleSubscription = new Subscription();
  }
  /**
   * Start watching for user idle and setup timer and ping.
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(fromEvent(window, 'mousemove'), fromEvent(window, 'resize'), fromEvent(document, 'keydown'));
    }
    this.idle$ = from(this.activityEvents$);
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
    // If any of user events is not active for idle-seconds when start timer.
    this.idleSubscription = this.idle$.pipe(bufferTime(this.idleSensitivityMillisec),
    // Starting point of detecting of user's inactivity
    filter(arr => !arr.length && !this.isIdleDetected && !this.isInactivityTimer), tap(() => {
      this.isIdleDetected = true;
      this.idleDetected$.next(true);
    }), switchMap(() => this._ngZone.runOutsideAngular(() => interval(1000).pipe(takeUntil(merge(this.activityEvents$, interval(1000).pipe(tap(seconds => {
      Oidc.Logger.info(`Tracking user idle time for ${seconds + 1} second(s)`);
    }), filter(x => {
      return x >= this.idleTime;
    }))
    // timer(this.idleTime*1000)
    .pipe(tap(_ => {
      this.isInactivityTimer = true;
      this.timerStart$.next(true);
    }))).pipe(tap(x => {
      if (x instanceof Event) {
        Oidc.Logger.info(`User interrupted at ${new Date()}`);
      } else {
        Oidc.Logger.info(`User goes idle at ${new Date()}`);
      }
    }))), finalize(() => {
      this.isIdleDetected = false;
      this.idleDetected$.next(false);
    }))))).subscribe();
    this.setupTimer(this.timeout);
  }
  stopWatching() {
    this.stopTimer();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }
  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }
  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }
  /**
   * Return observable for timer's countdown number that emits after idle.
   */
  onTimerStart() {
    return this.timerStart$.pipe(distinctUntilChanged(), switchMap(start => start ? this.timer$ : of(0)));
  }
  /**
   * Return observable for idle status changed
   */
  onIdleStatusChanged() {
    return this.idleDetected$.asObservable();
  }
  /**
   * Return observable for timeout is fired.
   */
  onTimeout() {
    return this.timeout$.pipe(filter(timeout => !!timeout), tap(() => this.isTimeout = true), map(() => true));
  }
  Setup(idleSeconds, timeoutSeconds) {
    this.idleTime = idleSeconds;
    this.timeout = timeoutSeconds;
  }
  /**
   * Set custom activity events
   *
   * @param customEvents Example: merge(
   *   fromEvent(window, 'mousemove'),
   *   fromEvent(window, 'resize'),
   *   fromEvent(document, 'keydown'),
   *   fromEvent(document, 'touchstart'),
   *   fromEvent(document, 'touchend')
   * )
   */
  setCustomActivityEvents(customEvents) {
    if (this.idleSubscription && !this.idleSubscription.closed) {
      console.error('Call stopWatching() before set custom activity events');
      return;
    }
    this.activityEvents$ = customEvents;
  }
  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  setupTimer2(timeout) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = of(new Date()).pipe(switchMap(startDate => interval(1000).pipe(map(() => Math.round((new Date().valueOf() - startDate.valueOf()) / 1000)),
      //   convert elapsed count to seconds
      tap(elapsed => {
        if (elapsed >= timeout) {
          this.timeout$.next(true);
        }
      }))));
    });
  }
  /**
   * Setup timer.
   *
   * Counts every seconds and return n+1 and fire timeout for last count.
   * @param timeout Timeout in seconds.
   */
  setupTimer(timeout) {
    this._ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(take(timeout), map(() => 1), scan((totalElapsed, n) => totalElapsed + n), tap(totalElapsed => {
        if (totalElapsed === timeout) {
          this.timeout$.next(true);
        }
      }), map(totalElapsed => timeout - totalElapsed) // return  no of seconds left for timeout
      );
    });
  }
}
UserIdleService.ɵfac = function UserIdleService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UserIdleService)(i0.ɵɵinject(i0.NgZone));
};
UserIdleService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: UserIdleService,
  factory: UserIdleService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UserIdleService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }];
  }, null);
})();
class PingAuthenticationService {
  constructor(initial_config, http, injector,
  // private _urlSerializer: UrlSerializer,
  // private _location: Location,
  userIdleService) {
    this.http = http;
    this.injector = injector;
    this.userIdleService = userIdleService;
    this.user$ = new BehaviorSubject(null);
    this.loading$ = new BehaviorSubject(true);
    this.errorMessage$ = new BehaviorSubject('');
    this.OnUnauthorizedRequest = new EventEmitter();
    this.OnTokenRenewalFailed = new EventEmitter();
    this.OnSignInComplete = new EventEmitter();
    this.OnSignOutBegin = new EventEmitter();
    this.isIdle$ = new BehaviorSubject(false);
    this.secondsLeft$ = new BehaviorSubject(0);
    this.timerStartSubscription = new Subscription();
    this.timeoutSubscription = new Subscription();
    this._appProfile = {};
    this._appLevelRoles$ = new BehaviorSubject([]);
    this.config = initial_config;
  }
  loadConfig() {
    if (this.config.load_from_url) {
      return this.http.get(`${this.config.load_from_url}?ts=${Date.now()}`).pipe(tap(_d => {
        this.loading$.next(false);
      }));
    }
    return of(this.config).pipe(tap(_d => {
      this.loading$.next(false);
    }));
  }
  endSession() {
    this.timerStartSubscription.unsubscribe();
    this.timeoutSubscription.unsubscribe();
    this.isIdle$.next(false);
    this.userIdleService.stopWatching();
    const router = this.injector.get(Router);
    router.navigate(['logout'], {
      queryParams: {
        redirectUrl: window.location.href
      }
    });
  }
  renewSession() {
    this.userIdleService.resetTimer();
    // this.userIdleService.stopTimer();
    this.isIdle$.next(false);
    this.secondsLeft$.next(0);
  }
  setupIdleTimer() {
    const support_offline_mode = this.config.support_offline_mode;
    this.userIdleService.Setup(Number(this.config.idle_threshold), Number(this.config.timeout_duration));
    this.timerStartSubscription = this.userIdleService.onTimerStart().pipe(tap(() => {
      const isIdle = support_offline_mode ? navigator.onLine : true;
      this.isIdle$.next(isIdle);
    })).subscribe(n => {
      if (this.config.log_level != Oidc.Log.NONE) {
        Oidc.Logger.info(`${n} seconds left for timeout`);
      }
      this.secondsLeft$.next(n);
    });
    this.timeoutSubscription = this.userIdleService.onTimeout().subscribe(_ => {
      if (this.config.log_level != Oidc.Log.NONE) {
        Oidc.Logger.info(`onTimeout fired. support_offline_mode:${support_offline_mode}.`);
      }
      if (!support_offline_mode || support_offline_mode && navigator.onLine) {
        this.endSession();
      }
    });
    this.userIdleService.startWatching();
  }
  unAuthorizeRequest(url) {
    this.OnUnauthorizedRequest.emit(url);
  }
  get User() {
    return this.user$.value;
  }
  get AccessToken() {
    return this.User ? this.User.access_token : '';
  }
  async initialize(settings) {
    this.manager = new UserManager(settings);
    this.manager.events.addSilentRenewError(this.onSilentRenewError);
    await this._loadUser();
    this.manager.events.addUserLoaded(async args => {
      await this._loadUser(); // load the new user
    });
  }
  async _loadUser() {
    const user = await this.manager.getUser();
    this.user$.next(user);
    if (user && user.access_token) {
      this._appProfile = await this.config.set_app_profile(user, this.config.environment);
      if (this.config.app_role_attribute) {
        const roles = this._appProfile[this.config.app_role_attribute];
        this.addRoles(roles);
      }
    }
  }
  saveConfiguration(config) {
    this.config = config;
  }
  get environment() {
    return this.config.environment || {};
  }
  onSilentRenewError(...ev) {
    Oidc.Logger.error(`Silent Token Renewal failed.`, ev);
  }
  /**
   * Returns whether user is currently loggedIn and active
   */
  get loggedIn$() {
    return this.user$.pipe(map(u => u != null && !u.expired));
  }
  get identity$() {
    return this.user$.asObservable().pipe(map(u => u));
  }
  /**
   * Provides decoded claims from access token
   */
  get profile$() {
    return this.identity$.pipe(filter(u => !!u), map(u => JSON.parse(Buffer.from(u.access_token.split('.')[1], 'base64').toString("utf8"))));
  }
  /**
   * Provides Custom Application Profile
   */
  get appProfile() {
    return this._appProfile;
  }
  /**
   * Provides a list of user roles if supported
   */
  get roles$() {
    if (!this.config.role_attribute) {
      return this._appLevelRoles$;
    }
    return this.loggedIn$.pipe(switchMap(loggedIn => {
      if (!loggedIn) {
        return this._appLevelRoles$;
      }
      return combineLatest([this.profile$, this._appLevelRoles$]).pipe(map(([profile, appLevelRoles]) => {
        let roles = [];
        if (profile && profile[this.config.role_attribute]) {
          roles = [].concat(profile[this.config.role_attribute]);
        }
        return [...roles, ...appLevelRoles];
      }));
    }));
  }
  /**
   * Check if user has any of the roles provided
   */
  hasAnyRole$(roles) {
    if (roles.length === 0) {
      return of(true);
    }
    return this.roles$.pipe(map(userRoles => userRoles.some(role => roles.indexOf(role) > -1)));
  }
  /**
   * Sets additional roles for user
   */
  addRoles(roles) {
    this._appLevelRoles$.next(roles);
  }
  getClaims() {
    return this.profile$.pipe(map(u => u));
  }
  /**
   * Provides Authroization header to be sent to backend
   */
  getAuthorizationHeaderValue() {
    return this.user$.pipe(filter(u => !!u), map(user => `${user.token_type} ${user.access_token}`));
  }
  get logoutUrl() {
    return `${this.config.use_html5_routing ? '' : '#'}/logout`;
  }
  async signinSilent(args) {
    try {
      await this.manager.signinSilent(args);
    } catch (err) {
      console.log(err);
      this.OnTokenRenewalFailed.emit(err);
      await this.manager.signinRedirect(args);
    }
  }
  async startAuthentication(args) {
    this.loading$.next(true);
    await this.manager.signinRedirect(args);
  }
  async completeAuthentication() {
    const router = this.injector.get(Router);
    const route = this.injector.get(ActivatedRoute);
    if (!route.snapshot.queryParams['code']) {
      this.loading$.next(false);
      this.errorMessage$.next(route.snapshot.queryParams['error_description'] || 'Unknown Error');
      console.error('Error occurred during signin', route.snapshot);
      router.navigate(['error'], {
        queryParams: route.snapshot.queryParams
      });
      return;
    }
    const url = window.location.href.replace('/#/', '/'); //this line is to fix the issue with hash routing(https://github.com/authts/oidc-client-ts/issues/734)
    const user = await this.manager.signinCallback(url);
    if (this.config.on_user_signin_complete) {
      await this.config.on_user_signin_complete();
    }
    if (user) {
      const missing_claims = [];
      const claims = JSON.parse(Buffer.from(user.access_token.split('.')[1], 'base64').toString("utf8"));
      for (const claim of this.config.access_token_claims) {
        if (!claims.hasOwnProperty(claim.name) && claim.required) {
          missing_claims.push(`${claim.name}(${claim.description})`);
        }
      }
      if (missing_claims.length > 0) {
        console.warn(`Claim(s): ${missing_claims} are not found in access token.Please check your configuration with Ping Federate team.`);
      }
    }
    this.OnSignInComplete.emit();
    if (user && user.state) {
      const {
        redirect_url
      } = user.state;
      router.navigateByUrl(redirect_url);
      return;
    }
    router.navigate(['/']);
  }
  async startSignOut(args) {
    if (this.config.on_user_signout_begin) {
      await this.config.on_user_signout_begin();
    }
    this.OnSignOutBegin.emit();
    if (this.config.use_azure_ad) {
      await this.manager.signoutRedirect(args);
      return;
    }
    // Signout from Siteminder
    // await this.manager.signoutRedirect(args);
    const user = await this.manager.getUser();
    const oidcClient = new Oidc.OidcClient(this.manager.settings);
    const signoutRequest = await oidcClient.createSignoutRequest({
      id_token_hint: user && user.id_token,
      ...args
    });
    const url = signoutRequest.url.replace('post_logout_redirect_uri', 'TargetResource');
    await this.manager.removeUser();
    window.location.href = url; // `${this.config.siteminder_url}/login/SMLogout.jsp?originalTarget=${encodeURIComponent(url)}`;
  }
  // TODO: Signout callback is not working yet
  async completeSignout() {
    await this.manager.signoutCallback();
    this.user$.next(null);
  }
  async signinSilentCallback() {
    const route = this.injector.get(ActivatedRoute);
    if (route.snapshot.queryParams['error']) {
      console.error('Error occurred during token renewal', route.snapshot.queryParams);
      await this.manager.signoutRedirect();
      return;
    }
    const url = window.location.href.replace('/#/', '/');
    await this.manager.signinCallback(url);
  }
  /**
   * Provides current Oidc Settings.
   */
  OidcSettings() {
    return this.manager.settings;
  }
}
PingAuthenticationService.ɵfac = function PingAuthenticationService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingAuthenticationService)(i0.ɵɵinject(_RUNTIME_PING_CONFIG), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(UserIdleService));
};
PingAuthenticationService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PingAuthenticationService,
  factory: PingAuthenticationService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingAuthenticationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [_RUNTIME_PING_CONFIG]
      }]
    }, {
      type: i1.HttpClient
    }, {
      type: i0.Injector
    }, {
      type: UserIdleService
    }];
  }, null);
})();
class UnauthorizedComponent {
  constructor() {}
}
UnauthorizedComponent.ɵfac = function UnauthorizedComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || UnauthorizedComponent)();
};
UnauthorizedComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UnauthorizedComponent,
  selectors: [["aa-ping-unauthorized"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function UnauthorizedComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "h2");
      i0.ɵɵtext(1, "401-Unauthorized Access. Check your role.");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UnauthorizedComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-unauthorized',
      template: "<h2>401-Unauthorized Access. Check your role.</h2>\n",
      styles: [""]
    }]
  }], function () {
    return [];
  }, null);
})();
class AuthCallbackComponent {
  constructor(authService) {
    this.authService = authService;
  }
  async ngOnInit() {
    await this.authService.completeAuthentication();
  }
}
AuthCallbackComponent.ɵfac = function AuthCallbackComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AuthCallbackComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService));
};
AuthCallbackComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AuthCallbackComponent,
  selectors: [["aa-ping-auth-callback"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function AuthCallbackComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "h2");
      i0.ɵɵtext(1, "Signing you in. Please wait ...");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthCallbackComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-auth-callback',
      template: "<h2>Signing you in. Please wait ...</h2>\n",
      styles: [""]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }];
  }, null);
})();
class SignoutCallbackComponent {
  constructor(authService) {
    this.authService = authService;
  }
  async ngOnInit() {
    await this.authService.completeSignout();
  }
}
SignoutCallbackComponent.ɵfac = function SignoutCallbackComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SignoutCallbackComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService));
};
SignoutCallbackComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SignoutCallbackComponent,
  selectors: [["aa-ping-signout-callback"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function SignoutCallbackComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "p");
      i0.ɵɵtext(1, "signout-callback works!");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignoutCallbackComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-signout-callback',
      template: "<p>signout-callback works!</p>\n",
      styles: [""]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }];
  }, null);
})();
class LoginComponent {
  constructor(authService, route) {
    this.authService = authService;
    this.route = route;
  }
  async ngOnInit() {
    const redirectUrl = this.route.snapshot.queryParams['redirect_url'];
    await this.authService.startAuthentication({
      state: {
        redirect_url: redirectUrl || '/'
      }
    });
  }
}
LoginComponent.ɵfac = function LoginComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LoginComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService), i0.ɵɵdirectiveInject(i2.ActivatedRoute));
};
LoginComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LoginComponent,
  selectors: [["aa-ping-login"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function LoginComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "h2");
      i0.ɵɵtext(1, " Redirecting to Login Page. Please wait ...\n");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoginComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-login',
      template: "<h2>\n  Redirecting to Login Page. Please wait ...\n</h2>\n",
      styles: [""]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }, {
      type: i2.ActivatedRoute
    }];
  }, null);
})();
class LogoutComponent {
  constructor(authService, route) {
    this.authService = authService;
    this.route = route;
  }
  async ngOnInit() {
    await this.authService.startSignOut({
      redirect_uri: this.route.snapshot.params['redirectUrl'] || window.location.origin
    });
  }
}
LogoutComponent.ɵfac = function LogoutComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LogoutComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService), i0.ɵɵdirectiveInject(i2.ActivatedRoute));
};
LogoutComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: LogoutComponent,
  selectors: [["aa-ping-logout"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function LogoutComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "h2");
      i0.ɵɵtext(1, " Signing you out. Please wait...\n");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LogoutComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-logout',
      template: "<h2>\n  Signing you out. Please wait...\n</h2>\n",
      styles: [""]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }, {
      type: i2.ActivatedRoute
    }];
  }, null);
})();
class RenewCallbackComponent {
  constructor(authService) {
    this.authService = authService;
  }
  async ngOnInit() {
    await this.authService.signinSilentCallback();
  }
}
RenewCallbackComponent.ɵfac = function RenewCallbackComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RenewCallbackComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService));
};
RenewCallbackComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RenewCallbackComponent,
  selectors: [["aa-ping-renew-callback"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function RenewCallbackComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "p");
      i0.ɵɵtext(1, "renew-callback works!");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RenewCallbackComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-renew-callback',
      template: "<p>renew-callback works!</p>\n",
      styles: [""]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }];
  }, null);
})();
class PingAuthGuard {
  constructor(router, authService) {
    this.router = router;
    this.authService = authService;
  }
  canActivate(route, state) {
    const roles = route.data && route.data['roles'] || [];
    const allow$ = forkJoin([this.authService.loggedIn$.pipe(first()), this.authService.hasAnyRole$(roles).pipe(first())]).pipe(map(([loggedIn, hasRole]) => {
      if (!loggedIn) {
        this.router.navigate(['login'], {
          queryParams: {
            redirect_url: state.url
          }
        });
        return false;
      }
      if (!hasRole) {
        this.router.navigate([this.authService.config.unauthorized_route]);
        return false;
      }
      return true;
    }));
    return allow$;
  }
  canLoad(route, _segments) {
    const roles = route.data && route.data['roles'] || [];
    const allow$ = forkJoin([this.authService.loggedIn$.pipe(first()), this.authService.hasAnyRole$(roles).pipe(first())]).pipe(map(([loggedIn, hasRole]) => {
      if (!loggedIn) {
        this.router.navigate(['login'], {
          queryParams: {
            // redirect_url: this.router.serializeUrl(this.router.createUrlTree(_segments.map(s => s.path)))
            redirect_url: this.router.getCurrentNavigation()?.extractedUrl
          }
        });
        return false;
      }
      if (!hasRole) {
        this.router.navigate([this.authService.config.unauthorized_route]);
        return false;
      }
      return true;
    }));
    return allow$;
  }
}
PingAuthGuard.ɵfac = function PingAuthGuard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingAuthGuard)(i0.ɵɵinject(i2.Router), i0.ɵɵinject(PingAuthenticationService));
};
PingAuthGuard.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PingAuthGuard,
  factory: PingAuthGuard.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingAuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i2.Router
    }, {
      type: PingAuthenticationService
    }];
  }, null);
})();
class PingTokenInterceptorService {
  constructor(authService, config) {
    this.authService = authService;
    this.config = config;
  }
  intercept(req, next) {
    let headers = req.headers;
    if (this.authService.config.authorization_exclusion_list.some(uri => req.url.includes(uri))) {
      return next.handle(req); // pass through if excluded
    }
    if (this.authService.AccessToken) {
      headers = headers.set(AUTHORIZATION, `Bearer ${this.authService.AccessToken}`);
    }
    const featureFlagsEndpoint = this.config.FeatureFlags && this.config.FeatureFlags.uri;
    const isFeatureFlagsRequest = req.url === featureFlagsEndpoint;
    const withCredentials = !isFeatureFlagsRequest;
    const authenticatedReq = req.clone({
      headers,
      withCredentials
    });
    return next.handle(authenticatedReq).pipe(catchError(err => {
      if (err.status === 401) {
        this.authService.unAuthorizeRequest(req.url);
      }
      return throwError(() => err);
    }));
  }
}
PingTokenInterceptorService.ɵfac = function PingTokenInterceptorService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingTokenInterceptorService)(i0.ɵɵinject(PingAuthenticationService), i0.ɵɵinject(PING_APP_ENVIRONMENT));
};
PingTokenInterceptorService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PingTokenInterceptorService,
  factory: PingTokenInterceptorService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingTokenInterceptorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PING_APP_ENVIRONMENT]
      }]
    }];
  }, null);
})();
class SessionTimeoutWarningDialogComponent {
  constructor(authService) {
    this.authService = authService;
    this.isIdle$ = this.authService.isIdle$;
    this.secondsLeft$ = this.authService.secondsLeft$;
  }
  extendSession() {
    this.authService.renewSession();
  }
  endSession() {
    this.authService.endSession();
  }
}
SessionTimeoutWarningDialogComponent.ɵfac = function SessionTimeoutWarningDialogComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SessionTimeoutWarningDialogComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService));
};
SessionTimeoutWarningDialogComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SessionTimeoutWarningDialogComponent,
  selectors: [["aa-session-timeout-warning-dialog"]],
  standalone: false,
  decls: 2,
  vars: 3,
  consts: [[4, "ngIf"], [1, "session-backdrop"], ["id", "session-timeout-dialog", "role", "dialog", 1, "session-dialog-container"], [1, "session-dialog"], [1, "session-dialog-content"], [1, "session-dialog-header"], [1, "session-dialog-header-title"], [1, "session-dialog-header-icon"], [1, "session-dialog-body"], [1, "session-dialog-footer"], ["type", "button", 1, "session-btn", "session-btn-default", 3, "click"], ["type", "button", 1, "session-btn", "session-btn-warning", 3, "click"]],
  template: function SessionTimeoutWarningDialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, SessionTimeoutWarningDialogComponent_ng_container_0_Template, 18, 3, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.isIdle$));
    }
  },
  dependencies: [i2$1.NgIf, i2$1.AsyncPipe],
  styles: [".session-backdrop[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:1040;background-color:#000;opacity:.5}.session-dialog-container[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:1050;display:block;overflow:hidden;-webkit-overflow-scrolling:touch;outline:0}.session-dialog[_ngcontent-%COMP%]{position:relative;width:auto;margin:10px}.session-dialog-content[_ngcontent-%COMP%]{box-shadow:0 5px 15px #0000007f;position:relative;background-color:#fff;background-clip:padding-box;border-radius:2px;box-shadow:0 3px 9px #0000007f;outline:0}.session-dialog-header[_ngcontent-%COMP%]{color:#d04904;padding:15px;border-bottom:1px solid #e5e5e5;border-top-left-radius:2px;border-top-right-radius:2px}.session-dialog-header-title[_ngcontent-%COMP%]{text-align:center;margin:0}.session-dialog-header-icon[_ngcontent-%COMP%]{font-size:27px;font-style:normal;font-weight:700}.session-dialog-body[_ngcontent-%COMP%]{text-align:center;position:relative;padding:15px;font-size:18px}.session-dialog-footer[_ngcontent-%COMP%]{padding:15px;text-align:center}.session-btn[_ngcontent-%COMP%]{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;padding:6px 12px;font-size:14px;line-height:1.42857143;border-radius:2px;-webkit-user-select:none;user-select:none}.session-btn-warning[_ngcontent-%COMP%]{color:#fff;background-color:#0078d2}.session-btn-default[_ngcontent-%COMP%]{color:#0078d2;background-color:#fff;border-color:#0078d2;margin-right:20px}@media (min-width: 768px){.session-dialog[_ngcontent-%COMP%]{width:350px;margin:30px auto}}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionTimeoutWarningDialogComponent, [{
    type: Component,
    args: [{
      selector: 'aa-session-timeout-warning-dialog',
      template: "<ng-container *ngIf=\"isIdle$ | async\">\n  <div class=\"session-backdrop\"></div>\n  <div id=\"session-timeout-dialog\" class=\"session-dialog-container\" role=\"dialog\">\n    <div class=\"session-dialog\">\n      <div class=\"session-dialog-content\">\n        <div class=\"session-dialog-header\">\n          <h4 class=\"session-dialog-header-title\">\n            <i class=\"session-dialog-header-icon\">&#x26A0;</i>\n            Warning - Session Timeout\n          </h4>\n        </div>\n        <div class=\"session-dialog-body\">\n          Your session is about to expire in {{ secondsLeft$ | async }} seconds.\n        </div>\n        <div class=\"session-dialog-footer\">\n          <button type=\"button\" class=\"session-btn session-btn-default\" (click)=\"endSession()\">End Session Now</button>\n          <button type=\"button\" class=\"session-btn session-btn-warning\" (click)=\"extendSession()\">Renew Session</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</ng-container>\n",
      styles: [".session-backdrop{position:fixed;inset:0;z-index:1040;background-color:#000;opacity:.5}.session-dialog-container{position:fixed;inset:0;z-index:1050;display:block;overflow:hidden;-webkit-overflow-scrolling:touch;outline:0}.session-dialog{position:relative;width:auto;margin:10px}.session-dialog-content{box-shadow:0 5px 15px #0000007f;position:relative;background-color:#fff;background-clip:padding-box;border-radius:2px;box-shadow:0 3px 9px #0000007f;outline:0}.session-dialog-header{color:#d04904;padding:15px;border-bottom:1px solid #e5e5e5;border-top-left-radius:2px;border-top-right-radius:2px}.session-dialog-header-title{text-align:center;margin:0}.session-dialog-header-icon{font-size:27px;font-style:normal;font-weight:700}.session-dialog-body{text-align:center;position:relative;padding:15px;font-size:18px}.session-dialog-footer{padding:15px;text-align:center}.session-btn{display:inline-block;margin-bottom:0;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid transparent;padding:6px 12px;font-size:14px;line-height:1.42857143;border-radius:2px;-webkit-user-select:none;user-select:none}.session-btn-warning{color:#fff;background-color:#0078d2}.session-btn-default{color:#0078d2;background-color:#fff;border-color:#0078d2;margin-right:20px}@media (min-width: 768px){.session-dialog{width:350px;margin:30px auto}}\n"]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }];
  }, null);
})();
class SigninErrorComponent {
  constructor() {}
}
SigninErrorComponent.ɵfac = function SigninErrorComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || SigninErrorComponent)();
};
SigninErrorComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SigninErrorComponent,
  selectors: [["aa-signin-error"]],
  standalone: false,
  decls: 2,
  vars: 0,
  template: function SigninErrorComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "h2");
      i0.ɵɵtext(1, "Login Failed. Please try again later.");
      i0.ɵɵelementEnd();
    }
  },
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SigninErrorComponent, [{
    type: Component,
    args: [{
      selector: 'aa-signin-error',
      template: "<h2>Login Failed. Please try again later.</h2>\n",
      styles: [""]
    }]
  }], function () {
    return [];
  }, null);
})();
const routes = [{
  path: 'login',
  component: LoginComponent
}, {
  path: 'logout',
  component: LogoutComponent
}, {
  path: 'auth-callback',
  component: AuthCallbackComponent
}, {
  path: 'renew-callback',
  component: RenewCallbackComponent
}, {
  path: 'signout-callback',
  component: SignoutCallbackComponent
}, {
  path: 'error',
  component: SigninErrorComponent
}, {
  path: 'error-401',
  component: UnauthorizedComponent
}];
const routerModuleforRoot = RouterModule.forChild(routes);
class PingAuthenticationRoutingModule {}
PingAuthenticationRoutingModule.ɵfac = function PingAuthenticationRoutingModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingAuthenticationRoutingModule)();
};
PingAuthenticationRoutingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PingAuthenticationRoutingModule
});
PingAuthenticationRoutingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[routerModuleforRoot]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingAuthenticationRoutingModule, [{
    type: NgModule,
    args: [{
      imports: [routerModuleforRoot],
      exports: []
    }]
  }], null, null);
})();
class PingOverlayComponent {
  constructor(authService) {
    this.authService = authService;
    this.loading$ = this.authService.loading$.pipe(delay(0));
    this.errorMessage$ = this.authService.errorMessage$.pipe(delay(0));
    this.error$ = this.authService.errorMessage$.pipe(map(x => !!x), delay(0));
    this.overlay$ = merge(this.loading$, this.error$);
  }
}
PingOverlayComponent.ɵfac = function PingOverlayComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingOverlayComponent)(i0.ɵɵdirectiveInject(PingAuthenticationService));
};
PingOverlayComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: PingOverlayComponent,
  selectors: [["aa-ping-overlay"]],
  standalone: false,
  decls: 2,
  vars: 3,
  consts: [["class", "overlay-backdrop", 4, "ngIf"], [1, "overlay-backdrop"], ["class", "overlay-box", 4, "ngIf"], [4, "ngIf"], [1, "overlay-box"], ["href", "/"]],
  template: function PingOverlayComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, PingOverlayComponent_div_0_Template, 7, 9, "div", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.overlay$));
    }
  },
  dependencies: [i2$1.NgIf, i2$1.AsyncPipe],
  styles: [".overlay-backdrop[_ngcontent-%COMP%]{position:fixed;inset:0;z-index:1040;background-color:#fff}.overlay-box[_ngcontent-%COMP%]{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);color:#000;z-index:1000}"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingOverlayComponent, [{
    type: Component,
    args: [{
      selector: 'aa-ping-overlay',
      template: "<div class=\"overlay-backdrop\" *ngIf=\"overlay$ | async\">\n  <h2 class=\"overlay-box\" *ngIf=\"loading$ | async\">Loading ...</h2>\n  <h2 class=\"overlay-box\" *ngIf=\"error$ | async\">Login Failed.<a href=\"/\">Try Again...</a></h2>\n  <pre *ngIf=\"error$ | async\">\n    {{ this.errorMessage$ | async }}\n  </pre>\n</div>\n",
      styles: [".overlay-backdrop{position:fixed;inset:0;z-index:1040;background-color:#fff}.overlay-box{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);color:#000;z-index:1000}\n"]
    }]
  }], function () {
    return [{
      type: PingAuthenticationService
    }];
  }, null);
})();
class PingDomService {
  constructor(componentFactoryResolver, injector) {
    this.componentFactoryResolver = componentFactoryResolver;
    this.injector = injector;
  }
  createComponentRef(component) {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    this.appRef = this.injector.get(ApplicationRef);
    this.appRef.attachView(componentRef.hostView);
    return componentRef;
  }
  getDomElementFromComponentRef(componentRef) {
    return componentRef.hostView.rootNodes[0];
  }
  addChild(child, parent = document.body) {
    parent.appendChild(child);
  }
  addComponent(component) {
    const componentContainerRef = this.createComponentRef(component);
    const componentContainerElement = this.getDomElementFromComponentRef(componentContainerRef);
    this.addChild(componentContainerElement);
  }
}
PingDomService.ɵfac = function PingDomService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingDomService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.Injector));
};
PingDomService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PingDomService,
  factory: PingDomService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingDomService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.ComponentFactoryResolver
    }, {
      type: i0.Injector
    }];
  }, null);
})();
class HasRoleDirective {
  constructor(templateRef, viewContainer, authService) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
    this.authService = authService;
    this.aaHasRole = [];
  }
  ngOnInit() {
    this.applyPermission();
  }
  applyPermission() {
    if (typeof this.aaHasRole === 'string') {
      this.aaHasRole = [this.aaHasRole];
    }
    this.authService.hasAnyRole$(this.aaHasRole).pipe(take(1)).subscribe(authorized => {
      this.viewContainer.clear();
      if (authorized) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    });
  }
}
HasRoleDirective.ɵfac = function HasRoleDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HasRoleDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(PingAuthenticationService));
};
HasRoleDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HasRoleDirective,
  selectors: [["", "aaHasRole", ""]],
  inputs: {
    aaHasRole: "aaHasRole"
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HasRoleDirective, [{
    type: Directive,
    args: [{
      // tslint:disable-next-line:directive-selector
      selector: '[aaHasRole]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }, {
      type: i0.ViewContainerRef
    }, {
      type: PingAuthenticationService
    }];
  }, {
    aaHasRole: [{
      type: Input
    }]
  });
})();
class IfRoleContext {
  constructor() {
    this.$implicit = false;
    this.ifRole = false;
  }
}
function assertTemplate(property, templateRef) {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${ɵstringify(templateRef)}'.`);
  }
}
class IfRoleDirective {
  constructor(_viewContainer, templateRef, authService) {
    this._viewContainer = _viewContainer;
    this.authService = authService;
    this._context = new IfRoleContext();
    this._thenTemplateRef = null;
    this._elseTemplateRef = null;
    this._thenViewRef = null;
    this._elseViewRef = null;
    this._onDestroy$ = new Subject();
    this._userRoles = [];
    this._ifRole = '';
    this._thenTemplateRef = templateRef;
  }
  ngOnInit() {
    this._applyPermission();
  }
  _applyPermission() {
    this.authService.roles$.pipe(takeUntil(this._onDestroy$)).subscribe(roles => {
      this._userRoles = roles;
      this._context.$implicit = this._context.ifRole = this._checkAccess(this._ifRole);
      this._updateView();
    });
  }
  /**
   * The role to evaluate as the condition for showing a template.
   */
  set ifRole(role) {
    this._ifRole = role;
    this._context.$implicit = this._context.ifRole = this._checkAccess(role);
    this._updateView();
  }
  /**
   * A template to show if the roles are evaluated to true.
   */
  set ifRoleThen(templateRef) {
    assertTemplate('ifRoleThen', templateRef);
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }
  /**
   * A template to show if the roles evaluates to false.
   */
  set ifRoleElse(templateRef) {
    assertTemplate('ifRoleElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }
  _checkAccess(role) {
    return !role || this._userRoles.indexOf(role) > -1;
  }
  _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
        }
      }
    }
  }
  ngOnDestroy() {
    this._onDestroy$.next(true);
    this._onDestroy$.unsubscribe();
  }
}
IfRoleDirective.ɵfac = function IfRoleDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || IfRoleDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(PingAuthenticationService));
};
IfRoleDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IfRoleDirective,
  selectors: [["", "ifRole", ""]],
  inputs: {
    ifRole: "ifRole",
    ifRoleThen: "ifRoleThen",
    ifRoleElse: "ifRoleElse"
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IfRoleDirective, [{
    type: Directive,
    args: [{
      selector: '[ifRole]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }, {
      type: i0.TemplateRef
    }, {
      type: PingAuthenticationService
    }];
  }, {
    ifRole: [{
      type: Input
    }],
    ifRoleThen: [{
      type: Input
    }],
    ifRoleElse: [{
      type: Input
    }]
  });
})();
function getConfiguration(config, runtimeConfig) {
  const DEFAULT_CONFIG = {
    authority: 'https://idp.aa.com',
    base_href: '',
    scope: 'openid',
    log_level: Log.NONE,
    idle_threshold: 14 * 60,
    timeout_duration: 60,
    authorization_exclusion_list: [],
    use_html5_routing: true,
    idle_warning_dialog_component: SessionTimeoutWarningDialogComponent,
    role_attribute: 'role',
    automatic_silent_renew: true,
    load_user_info: false,
    unauthorized_route: 'error-401',
    use_session_storage: true,
    use_azure_ad: false,
    on_user_signin_complete: () => Promise.resolve(),
    on_user_signout_begin: () => Promise.resolve(),
    set_app_profile: () => Promise.resolve({}),
    app_role_attribute: '',
    access_token_expiring_notification_time: 300,
    support_offline_mode: false,
    access_token_claims: []
  };
  const final_config = {
    ...DEFAULT_CONFIG,
    ...config,
    ...runtimeConfig
  };
  if (final_config.use_azure_ad) {
    if (!final_config.azure_tenant_id) {
      throw new Error('Azure Tenant ID is required for Azure AD');
    }
    if (!final_config.azure_api_application_id) {
      throw new Error('Azure API Application ID is required for Azure AD');
    }
    final_config.authority = `${AZURE_AD_PROVIDER}/${final_config.azure_tenant_id}`;
    final_config.role_attribute = 'roles';
    if (final_config.scope.indexOf(final_config.azure_api_application_id) === -1) {
      final_config.scope += ` api://${final_config.azure_api_application_id}`;
    }
  }
  final_config.authorization_exclusion_list.push(AZURE_AD_PROVIDER);
  if (final_config.authority) {
    final_config.authorization_exclusion_list.push(final_config.authority);
  }
  if (final_config.client_secret) {
    throw new Error('Ping Client Secret is no longer supported.Please check with Ping Federate team to disable ClientSecret for your ClientId');
  }
  return final_config;
}
// Can be used for nonce
/*
export function randomString(length: number) {
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
  let result = '';

  while (length > 0) {
      const bytes = new Uint8Array(16);
      const random = window.crypto.getRandomValues(bytes);

      random.forEach(function(c) {
          if (length === 0) {
              return;
          }
          if (c < charset.length) {
              result += charset[c];
              length--;
          }
      });
  }
  return result;
}
*/
function getOidcConfiguration(config) {
  let base_url = window.location.origin;
  if (config.base_href !== '') {
    base_url += `/${config.base_href}`;
  }
  const userManagerSettings = {
    client_id: config.client_id,
    client_secret: config.client_secret,
    response_type: 'code',
    response_mode: 'query',
    scope: config.scope,
    authority: config.authority,
    redirect_uri: `${base_url}/${config.use_html5_routing ? '' : '#/'}auth-callback`,
    post_logout_redirect_uri: config.post_logout_redirect_uri,
    // post_logout_redirect_uri: `${base_url}/#/signout-callback`,
    // post_logout_redirect_uri: `${base_url}`,
    //silent_redirect_uri: `${base_url}/${config.use_html5_routing ? '' : '#/'}renew-callback`,
    silent_redirect_uri: `${base_url}/silent-callback.html`,
    automaticSilentRenew: config.automatic_silent_renew,
    includeIdTokenInSilentRenew: true,
    accessTokenExpiringNotificationTimeInSeconds: config.access_token_expiring_notification_time,
    loadUserInfo: !config.use_azure_ad ? config.load_user_info : false,
    filterProtocolClaims: true,
    fetchRequestCredentials: 'include',
    userStore: getStorage(config.use_session_storage),
    ...(!config.use_azure_ad && {
      metadata: {
        issuer: config.authority,
        authorization_endpoint: `${config.authority}/as/authorization.oauth2`,
        userinfo_endpoint: `${config.authority}/idp/userinfo.openid`,
        jwks_uri: `${config.authority}/pf/JWKS`,
        token_endpoint: `${config.authority}/as/token.oauth2`,
        end_session_endpoint: `${config.authority}/idp/startSLO.ping`,
        revocation_endpoint: `${config.authority}/as/revoke_token.oauth2`,
        introspection_endpoint: `${config.authority}/as/introspect.oauth2`
      }
    }),
    ...(config.use_azure_ad && {
      extraQueryParams: {
        resource: config.azure_api_application_id ? `api://${config.azure_api_application_id}` : 'https://graph.microsoft.com'
      }
    })
  };
  // https://stackoverflow.com/questions/66348270/azure-ad-v2-roles-not-included-in-access-token
  // https://www.taithienbo.com/using-oidc-client-js-to-obtain-tokens-from-azure-ad-v1-0-or-microsoft-identity-platform-v2-0/
  return userManagerSettings;
}
function appInitializerFn(config, preloaded, authService, domService) {
  const initializeWithLoadedConfig = () => {
    return new Promise((resolve, reject) => {
      if (!config.client_id) {
        reject(`Invalid configuration.Please specify "client_id".`);
      }
      domService.addComponent(config.idle_warning_dialog_component);
      domService.addComponent(PingOverlayComponent);
      Oidc.Log.setLogger(console);
      Oidc.Log.setLevel(config.log_level || Log.NONE);
      authService.initialize(getOidcConfiguration(config)).then(() => {
        authService.saveConfiguration(config);
        if (config.automatic_silent_renew && config.idle_threshold !== 0) {
          authService.setupIdleTimer();
        }
        resolve();
      });
    });
  };
  const loadConfigAndInitialize = () => firstValueFrom(authService.loadConfig()).then(loadedConfig => {
    const newConfig = {
      ...config,
      ...loadedConfig
    };
    if (!newConfig.client_id) {
      throw new Error(`Invalid configuration.Please specify client_id.`);
    }
    domService.addComponent(newConfig.idle_warning_dialog_component);
    domService.addComponent(PingOverlayComponent);
    Oidc.Log.setLogger(console);
    Oidc.Log.setLevel(config.log_level || Log.NONE);
    authService.initialize(getOidcConfiguration(newConfig));
    authService.saveConfiguration(newConfig);
    if (newConfig.automatic_silent_renew && newConfig.idle_threshold !== 0) {
      authService.setupIdleTimer();
    }
  }).catch(err => {
    console.error('Application Failed to initialize', err);
  });
  return preloaded ? initializeWithLoadedConfig : loadConfigAndInitialize;
}
/**
 * Setup PingFederate Authentication module for the application
 * Usage:
 *
 * ```typescript
 * @NgModule({
 *   declarations: [AppComponent],
 *   imports: [
 *     BrowserModule,
 *     BrowserAnimationsModule,
 *     NxModule.forRoot(),
 *     StoreModule.forRoot({}),
 *     EffectsModule.forRoot([AppEffects]),
 *     ToastrModule.forRoot({ timeOut: 10000, preventDuplicates: true }),
 *     PingAuthenticationModule.forRoot(
 *      {
 *        load_from_url: '/assets/app_config.json',
 *         idle_warning_dialog_component: CustomSessionTimeoutWarningDialogComponent,
 *      }),
 *     AppRoutingModule, //Make sure to import your routing module after PingAuthenticationModule
 *   ],
 *   bootstrap: [AppComponent],
 *   entryComponents: [ CustomSessionTimeoutWarningDialogComponent ]
 * })
 * export class AppModule {
 * }
 * ```
 */
class PingAuthenticationModule {
  static forRoot(config) {
    return {
      ngModule: PingAuthenticationModule,
      providers: [PingAuthGuard, PingAuthenticationService, PingDomService, {
        provide: _PING_CONFIG,
        useValue: config
      }, {
        provide: _RUNTIME_PING_CONFIG,
        useFactory: getConfiguration,
        deps: [_PING_CONFIG, _PING_CONFIG_FALLBACK]
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: PingTokenInterceptorService,
        multi: true
      }, {
        provide: APP_INITIALIZER,
        useFactory: appInitializerFn,
        multi: true,
        deps: [_RUNTIME_PING_CONFIG, _PRELOADED_PING_CONFIG, PingAuthenticationService, PingDomService]
      }]
    };
  }
}
PingAuthenticationModule.ɵfac = function PingAuthenticationModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PingAuthenticationModule)();
};
PingAuthenticationModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: PingAuthenticationModule
});
PingAuthenticationModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, RouterModule, PingAuthenticationRoutingModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PingAuthenticationModule, [{
    type: NgModule,
    args: [{
      declarations: [AuthCallbackComponent, SignoutCallbackComponent, LoginComponent, LogoutComponent, RenewCallbackComponent, SessionTimeoutWarningDialogComponent, PingOverlayComponent, HasRoleDirective, IfRoleDirective, SigninErrorComponent, UnauthorizedComponent],
      imports: [CommonModule, RouterModule, PingAuthenticationRoutingModule],
      exports: [HasRoleDirective, IfRoleDirective],
      entryComponents: [SessionTimeoutWarningDialogComponent, PingOverlayComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ping-authentication
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AUTHORIZATION, HasRoleDirective, IfRoleDirective, Log, PING_APP_ENVIRONMENT, PING_CONFIG, PingAuthGuard, PingAuthenticationModule, PingAuthenticationService, appInitializerFn, getConfiguration, getOidcConfiguration, getStorage };
